import { useRef } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Layout, { GradientBackground } from '../components/Layout';
import { today, getLocalTimeZone } from '@internationalized/date';
import {
  Logo,
  AnimatedSprite,
  Icon,
  ForestRoomLogo,
  CapricornLogo,
  DatePicker,
  WBG,
  LonelyDog,
  Microphone,
  Saturday,
  Button,
  AnimateOnVisible,
  AriaCalendar,
} from '../components';
import { getGlobalData } from '../utils/global-data';
import SEO from '../components/SEO';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import parse from 'html-react-parser';
// import { Queen } from 'https://sunset-inn-dev.s3.ca-central-1.amazonaws.com/gallery/queen.webp'
const schema = {
  '@context': 'http://schema.org',
  '@type': 'LodgingBusiness',
  name: 'Sunset Inn Kenora',
  description:
    'Welcome to Sunset Inn, Kenora - where comfort meets convenience. Our pet-friendly hotel offers cozy accommodations and modern amenities, nestled in the heart of the city. Discover an easy and relaxing stay with us, tailored to both you and your vacation needs.',
  image: [
    'https://sunsetinn.lotwebdesign.com/_next/image?url=https%3A%2F%2Fsunset-inn-dev.s3.ca-central-1.amazonaws.com%2Fgallery%2Fking-room-tv-microwave-coffee-maker.webp',
    'https://sunsetinn.lotwebdesign.com/_next/image?url=https%3A%2F%2Fsunset-inn-dev.s3.ca-central-1.amazonaws.com%2Fgallery%2Foutside-drive-up-rooms-motel-view.webp',
    'https://sunsetinn.lotwebdesign.com/_next/image?url=https%3A%2F%2Fsunset-inn-dev.s3.ca-central-1.amazonaws.com%2Fgallery%2Fking-forest-window-view.webp',
  ],
  address: {
    '@type': 'PostalAddress',
    addressLocality: 'Kenora',
    addressRegion: 'ON',
    postalCode: 'P9N1L9',
    streetAddress: '920 Highway 17 E',
  },
  priceRange: '$100 - $300',
  starRating: {
    '@type': 'Rating',
    ratingValue: '4',
    bestRating: '5',
  },
  hasMap: 'https://sunsetinn.lotwebdesign.com/near-by?d=restaurant',
  telephone: '8074682003',
  openingHoursSpecification: {
    '@type': 'OpeningHoursSpecification',
    dayOfWeek: [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ],
    opens: '00:00',
    closes: '23:59',
  },
  checkinTime: '15:00',
  checkoutTime: '11:00',
  petsAllowed: 'true',
  smokingAllowed: 'true',
  amenityFeature: [
    {
      '@type': 'LocationFeatureSpecification',
      name: 'Free wifi',
      value: 'True',
    },
    {
      '@type': 'LocationFeatureSpecification',
      name: '24-hour front desk',
      value: 'True',
    },
    {
      '@type': 'LocationFeatureSpecification',
      name: 'Air conditioning',
      value: 'True',
    },
    {
      '@type': 'LocationFeatureSpecification',
      name: 'Free oversized parking',
      value: 'True',
    },
    {
      '@type': 'LocationFeatureSpecification',
      name: 'Overnight security',
      value: 'True',
    },
    {
      '@type': 'LocationFeatureSpecification',
      name: 'On-site lounge',
      value: 'True',
    },
    {
      '@type': 'LocationFeatureSpecification',
      name: 'Banquet facilities',
      value: 'True',
    },
    {
      '@type': 'LocationFeatureSpecification',
      name: 'Meeting/boardroom facilities',
      value: 'True',
    },
    {
      '@type': 'LocationFeatureSpecification',
      name: 'Daily housekeeping',
      value: 'True',
    },
    {
      '@type': 'LocationFeatureSpecification',
      name: 'Cable TV',
      value: 'True',
    },
    {
      '@type': 'LocationFeatureSpecification',
      name: 'In-room fridge',
      value: 'True',
    },
    {
      '@type': 'LocationFeatureSpecification',
      name: 'In-room microwave',
      value: 'True',
    },
    {
      '@type': 'LocationFeatureSpecification',
      name: 'In-room coffee maker',
      value: 'True',
    },
    {
      '@type': 'LocationFeatureSpecification',
      name: 'Wake-up service',
      value: 'True',
    },
    {
      '@type': 'LocationFeatureSpecification',
      name: 'On-site BBQ facilities',
      value: 'True',
    },
    {
      '@type': 'LocationFeatureSpecification',
      name: 'Pet friendly',
      value: 'True',
    },
    {
      '@type': 'LocationFeatureSpecification',
      name: 'Oversized Vehicle Parking',
      value: 'True',
    },
    {
      '@type': 'LocationFeatureSpecification',
      name: 'Playpens Available',
      value: 'True',
    },
    {
      '@type': 'LocationFeatureSpecification',
      name: 'Cots Available',
      value: 'True',
    },
  ],
  currenciesAccepted: ['CAD', 'USD'],
  email: '',
  url: 'https://www.sunsetinnkenora.com',
};

const Wrapper = ({ children }) => {
  return (
    <ResponsiveMasonry
      columnsCountBreakPoints={{ 360: 1, 768: 2, 992: 2, 1280: 5 }}
      className="w-full z-10 respm"
    >
      <Masonry gutter="2px">{children}</Masonry>
    </ResponsiveMasonry>
  );
};
const fetch = require('node-fetch');

const directory = [
  {
    name: 'McDonalds',
    walking: 1,
    driving: 0,
  },
  {
    name: 'KFC',
    walking: 1,
    driving: 0,
  },
  {
    name: 'A&W',
    walking: 1,
    driving: 1,
  },
  {
    name: 'Lake of the Woods Cannabis',
    walking: 4,
    driving: 1,
  },
  {
    name: 'Canadian Tire',
    walking: 5,
    driving: 1,
  },
  {
    name: 'Walmart',
    walking: 6,
    driving: 1,
  },
  {
    name: 'Dog Park',
    walking: 7,
    driving: 1,
  },
  {
    name: 'K-Sports Marina',
    walking: 8,
    driving: 1,
  },
  {
    name: 'Kenora Downtown',
    walking: 17,
    driving: 3,
  },
  {
    name: 'Kenora Harbourfront',
    walking: 20,
    driving: 4,
  },
];
async function sendRequest(url, options = {}) {
  const res = await fetch(url, options);

  if (!res.ok) {
    switch (res.status) {
      case 400:
        throw new Error('Bad Request');
      case 401:
        throw new Error('Unauthorized');
      case 404:
        throw new Error('Not Found');
      case 500:
        throw new Error('Internal Server Error');
      case 2000:
      default:
        throw new Error(`HTTP error: ${res.status}`);
    }
  }

  return await res;
}
const images = [
  {
    name: 'king-room-tv-microwave-coffee-maker',
    aspect: 'aspect-[3/2]',
  },
  {
    name: 'king-amenities-couch-chair-window-view',
    aspect: 'aspect-[2/3]',
  },
  {
    name:'sunset-inn-sign-aerial-view',
    aspect:'aspect-[4/3]'
  },
  // {
  //   name: 'outside-room',
  //   aspect: 'aspect-[3/4]',
  // },
  // {
  //   name: 'sunset-room-2',
  //   aspect: 'aspect-[3/2]',
  // },
  // {
  //   name: 'outside-room-bathroom',
  //   aspect: 'aspect-[3/4]',
  // },
  {
    name: 'amenties-fridge',
    aspect: 'aspect-[2/3]',
  },

  // {
  //   name: 'forest-room-2',
  //   aspect: 'aspect-[2/3]',
  // },
  {
    name: 'outside-drive-up-rooms-motel-view',
    aspect: 'aspect-[3/2]',
  },
  {
    name: 'amenities-bathroom-shampoo-conditioner-lotion-sanitizer-bar-soap',
    aspect: 'aspect-[3/2]',
  },
  {
    name: 'king-with-5-pillows',
    aspect: 'aspect-[3/2]',
  },
  {
    name: 'king-bed-and-couch',
    aspect: 'aspect-[3/2]',
  },
  // {
  //   name: 'outside-rooms-motel',
  //   aspect: 'aspect-[3/2]',
  // },
  // {
  //   name: '2-queens-from-window',
  //   aspect: 'aspect-[16/9]',
  // },
  {
    name: 'capricorn-lounge-outside-view',
    aspect: 'aspect-[3/2]',
  },
  // {
  //   name: '2-queens',
  //   aspect: 'aspect-[16/9]',
  // },
  // {
  //   name: 'queen',
  //   aspect: 'aspect-[4/3]',
  // },
  // {
  //   name: 'pool-table-capricorn-lounge',
  //   aspect: 'aspect-[3/2]',
  // },
  // {
  //   name: 'fitness-room',
  //   aspect: 'aspect-[4/3]',
  // },
  // {
  //   name: 'sunset-inn-room',
  //   aspect: 'aspect-[4/3]',
  // },
  {
    name: 'amenities-phone-alarm-pad',
    aspect: 'aspect-[3/2]',
  },
  {
    name: 'king-forest-window-view',
    aspect: 'aspect-[3/2]',
  },
  {
    name:'spacer-1',
    aspect:'aspect-[3/2]'
  },
  {
    name: 'king-bathroom-bathtub',
    aspect: 'aspect-[3/2]',
  },
  {
    name:'spacer-2',
    aspect:'aspect-[3/2]'
  },
  {
    name:'sunset-inn-aerial-view-main',
    aspect:'aspect-[3/2]'
  }
  // {
  //   name: 'forest-room',
  //   aspect: 'aspect-[2/3]',
  // },
  // {
  //   name: 'oakroom',
  //   aspect: 'aspect-[3/2]',
  // },
  // {
  //   name: 'bathroom',
  //   aspect: 'aspect-[3/2]',
  // },

  // {
  //   name: 'sunset-room',
  //   aspect: 'aspect-[3/2]',
  // },
];
const categories = [
  {
    name: 'Dine',
    icon: 'restaurant',
    long: 'Restaurants',
  },
  {
    name: 'Indulge',
    icon: 'indulge',
    long: 'Indulgences',
  },
  {
    name: 'Pets',
    icon: 'pet',
    long: 'For Your Pet',
  },
  {
    name: 'Shop',
    icon: 'shopping',
    long: 'Shopping Destinations',
  },
  {
    name: 'Vehicles',
    icon: 'car',
    long: 'For Your Vehicle',
  },
  {
    name: 'Fish',
    icon: 'fishing',
    long: 'Fishing',
  },
  {
    name: 'Coffee',
    icon: 'coffee',
    long: 'Coffee/Cafes',
  },
  {
    name: 'Sights',
    icon: 'attractions',
    long: 'Attractions',
  },
  {
    name: 'Rec',
    icon: 'recreation',
    long: 'Parks and Recreation',
  },
  {
    name: 'Fill',
    icon: 'gas',
    long: 'Fill or Charge',
  },
];
const handlePetEntry = () => {
  console.log('PET SECTION VISIBLE');
};
export default function Index({ posts, globalData }) {
  //refs for fade ins
  const petRef2 = useRef();
  const petRef3 = useRef();
  const petRef4 = useRef();
  return (
    <Layout currentPage="home">

      {/* <div className="fixed inset-0 w-full aspect-[4/3] z-0 opacity-30">      
        <AnimateOnVisible delay={200} duration={100}>
        <Image
          src="/assets/sunset-background-with-crane.webp"
          alt=""
          fill
          style={{ objectFit: 'cover' }}
        />
        </AnimateOnVisible>
      </div>
   */}
      <SEO title={globalData.name} description={globalData.blogTitle} />
      
      <Header name={'Sunset Inn Kenora'} />
      <AnimateOnVisible delay={300} duration={200}>
      <div className="w-full flex flex-wrap items-end justify-center z-10">
        <div className="w-full flex flex-col items-center xl:flex-row mb-5">
          <div className="w-5/6 md:w-2/3 lg:w-1/2 flex flex-col items-center">
            <div className="flex flex-col w-full xl:ml-10 items-center">
              <Logo className="mb-5 w-[200px] md:w-[250px] xl:w-1/2 max-w-[300px] z-10" />
              <div className="w-full flex justify-center items-end">
                <div className="relative overflow-visible h-[175px] aspect-[83/586]">
                  <Image
                    src="/assets/bedpost.webp"
                    alt=""
                    fill
                    priority
                  />
                  <div className="invisible dark:visible absolute top-[-120px] left-[-25px] w-full h-[130px] overflow-visible">
                    <AnimatedSprite
                      spriteSheet="https://sunset-inn-dev.s3.ca-central-1.amazonaws.com/main/assets/fire-spritesheet.webp"
                      frameWidth="103"
                      frameHeight="100"
                      frameCount="5"
                      animationSpeed="100"
                    />
                  </div>
                </div>
                <div className="w-11/12 p-2 mt-10 bg-island rounded-t-xl bg-gradient-to-t from-island via-white to-island relative">
                  <div className="w-full font-cabin p-2 bg-lightblue dark:bg-island rounded-xl border-2 border-island">
                    <h1 className="text-2xl md:text-3xl text-center font-overlock font-black">
                      Sunset Inn Kenora
                    </h1>
                    <p className="text-center text-sm md:text-base lg:text-xl text-black dark:text-sun mb-1">
                      Lose yourself in the comforting warmth of Sunset Inn Kenora,
                      your relaxing retreat surrounded by the scenic charm and
                      captivating atmosphere of Kenora Ontario.
                    </p>
                  </div>
                </div>
                <div className="relative overflow-visible h-[175px] aspect-[83/586] md:translate-y-[-5%] md:scale-y-[1.1] relative">
                  <Image
                    src="/assets/bedpost.webp"
                    alt=""
                    fill
                    priority
                  />
                  <div className="invisible dark:visible  absolute top-[-120px] left-[-25px] w-full h-[130px] overflow-visible">
                    <AnimatedSprite
                      spriteSheet="/assets/fire-spritesheet.webp"
                      frameWidth="103"
                      frameHeight="100"
                      frameCount="5"
                      animationSpeed="100"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
      <div className="w-full sm:w-1/2 flex justify-center">
          <AriaCalendar
            minValue={today(getLocalTimeZone())}
            maxValue={today(getLocalTimeZone()).add({ days: 365 })}
            today={today(getLocalTimeZone())}
            visibleRange={{
              start: today(getLocalTimeZone()),
              end: today(getLocalTimeZone()).add({ days: 100 }),
            }}
            defaultValue={{
              start: today(getLocalTimeZone()),
              end: today(getLocalTimeZone()).add({ days: 1 }),
            }}
          />
        </div></div>
      </div>
      </AnimateOnVisible>
      <div className="md:hidden h-[75px] w-[90%] mb-[-50px] bg-gradient-to-b from-island to-transparent" />
      <div className="overflow-hidden w-full bg-gradient-to-b from-transparent via-waterLight to-waterDark md:from-sun md:via-sun md:to-citron dark:from-island dark:via-island dark:to-black z-20 md:border-4 md:border-x-0 border-island pb-2 md:p-5">
      </div>
      <div id="test" className="contents"></div>
      <Wrapper>
        {images.map((i, key) => (
          <AnimateOnVisible className={i.name.match('spacer') ? 'h-0' : ''} key={i.name} duration={200} delay={100}>
          <div
            key={i.name}
            className={`relative w-[auto] lg:w-full h-[300px] lg:h-[auto] flex ${i.aspect}`}
          >
            {
              !i.name.match('spacer')
              ?
                <Image
                  src={`https://sunset-inn-dev.s3.ca-central-1.amazonaws.com/gallery/${i.name}.webp`}
                  sizes="(min-width: 1280px) 20vw, 50vw"
                  alt={i.name.replaceAll('-', ' ')}
                  fill
                  priority
                />
              :
              <>
              </>
            }
          </div>
          </AnimateOnVisible>
        ))}
      </Wrapper>
      <div
        className="relative flex flex-col items-center z-10 w-full py-16"
      >
        <div className="absolute inset-0 bg-gradient-to-b from-island via-lightblue to-island opacity-80" />
        <div
          className="absolute inset-0 opacity-20 dark:opacity-10 overflow-hidden"
        >
          <div className="relative w-full aspect-[960/671]">
          <Image className="" src="/assets/paw-background.webp" sizes="50vw" fill alt=""/>
          </div>
        </div>
        <div className="absolute inset-0 m-1 lg:m-10 bg-white dark:bg-black opacity-40 border-4 border-island md:rounded-[10px] md:rounded-[100px]" />
        <>
          <AnimateOnVisible duration={200} delay={500}>
            <WBG
              type="h3"
              className="text-center font-black mb-5 leading-relaxed px-5"
              classNames={{
                front:
                  'text-2xl lg:text-5xl xl:text-6xl bg-gradient-to-l from-black via-island to-black dark:from-sun dark:via-waterLight dark:to-sun bg-clip-text text-transparent font-overlock z-10 tracking-wide',
                back: 'text-2xl lg:text-5xl xl:text-6xl blur font-overlock opacity-90 text-white dark:text-black elevation-10',
              }}
            >
              Thinking of leaving family behind?!
            </WBG>
          </AnimateOnVisible>
          <LonelyDog className="w-[150px] lg:w-[250px] z-10 relative" />
          <AnimateOnVisible duration={200} delay={500}>
            <WBG
              type="h3"
              className="text-center text-6xl font-black mb-5 my-3 leading-relaxed px-5"
              classNames={{
                front:
                  'text-2xl lg:text-5xl xl:text-6xl bg-gradient-to-l from-black via-island to-black dark:from-sun dark:via-waterLight dark:to-sun bg-clip-text text-transparent font-overlock tracking-wide z-10',
                back: 'text-2xl lg:text-5xl xl:text-6xl xl:text-6xl blur scale-[1.1] font-overlock text-white dark:text-black',
              }}
            >
              Not if the Sunset Inn can help!
            </WBG>
            <p className="w-2/3 lg:w-2/3 text-base md:text-xl xl:text-2xl my-2 text-island dark:text-sun z-10 font-bold text-center font-cabin bg-gradient-to-b from-lightblue via-white to-lightblue dark:from-black dark:via-black dark:to-island p-4 lg:p-10 lg:rounded-[50px] border-2 lg:border-4 border-island elevation-20">
              Sunset Inn is 100% pet friendly and conveniently located next to
              the
              <span className="animate-blink"> City of Kenora Dog Park!</span>
            </p>
          </AnimateOnVisible>

          <div className="w-full md:w-1/3 flex justify-center">
            <Link
              href={`https://www.onressystems.com/reservations/search?property=sunset-inn`}
            >
              <AnimateOnVisible duration={200} delay={200}>
                <Button>BOOK A PET ROOM</Button>
              </AnimateOnVisible>
            </Link>
          </div>
        </>
      </div>

      <div
        className="w-full md:pb-10 flex flex-wrap justify-center items-center relative elevation-20 relative">
        <div className="relative w-full flex justify-center">
          <AnimateOnVisible className="contents" type="dim" duration={1000} delay={200}>
            <div className="flex flex-col items-center z-30" ref={petRef2}>
              <div className="absolute inset-0 bg-island">
                <Image
                  src="https://sunset-inn-dev.s3.ca-central-1.amazonaws.com/forest-room/assets/treeline.webp"
                  alt="map showing sunset inn and surrounding amenities"
                  fill
                  style={{ objectFit: 'cover' }}
                />
              </div>
            </div>
          </AnimateOnVisible>
          <AnimateOnVisible duration={200} delay={1000}>
            <div className="flex flex-col items-center z-30" ref={petRef3}>
              <ForestRoomLogo />
            </div>
          </AnimateOnVisible>
        </div>
        <div className="flex flex-col w-full relative">
          <div className="absolute inset-0 z-20 bg-gradient-to-b from-lightblue via-white to-lightblue dark:from-island dark:via-black dark:to-island" />
          <AnimateOnVisible duration={200} delay={1000}>
            <WBG
              type="h3"
              className="relative w-full text-transparent text-center font-black z-10  z-20"
              classNames={{
                front:
                  'text-2xl lg:text-5xl bg-gradient-to-b from-black via-black to-avocado dark:from-sun dark:via-sun dark:to-avocado bg-clip-text rounded font-overlock z-20 py-5 lg:py-10',
                back: 'font-overlock text-2xl lg:text-5xl text-sun dark:bg-island translate-x-[1px] translate-y-[1px] py-5 lg:py-10',
              }}
            >
              <div
                className="animate-dur-100 animate-del-1000 flex flex-col items-center z-30 w-full opacity-1"
                ref={petRef4}
              >
                The Forest Room Restaurant
              </div>
            </WBG>
          </AnimateOnVisible>
        </div>

        <div className="relative w-full flex flex-wrap justify-center items-center pt-10 md:p-10 bg-transparent z-10">
          <div className="absolute top-0 bottom-0 left-[7.5%] w-11/12 md:w-1/2 h-full bg-sun dark:bg-avocado dark:bg-gradient-to-tl dark:from-avocade dark:via-avocado dark:to-island z-10 rotate-[25deg] md:rotate-[15deg]" />
          <div className="absolute top-0 bottom-0 right-[7.5%] w-11/12 md:w-1/2 h-full bg-sun dark:bg-avocado dark:bg-gradient-to-tl dark:from-avocade dark:via-avocado dark:to-island z-10 rotate-[-25deg] md:rotate-[-15deg]" />
          <div className="absolute top-0 right-[25%] left-[25%] w-1/2 h-1/2 bg-sun dark:bg-avocado dark:bg-gradient-to-tl dark:from-avocade dark:via-avocado dark:to-island z-10" />
          <AnimateOnVisible duration={200} delay={1000}>
            <div className="w-full flex text-center text-base md:text-xl lg:text-2xl font-cabin px-20 lg:px-10 md:max-w-7xl z-20">
              Indulge in the serene beauty of nature at The Forest Room, where
              masterful culinary artistry meets the mesmerizing backdrop of
              forest and wildlife. Experience an unforgettable dining encounter
              that engages and delights all your senses
            </div>
          </AnimateOnVisible>
          <AnimateOnVisible duration={200} delay={1000}>
            <div className="w-full flex mt-10 overflow-x-scroll md:overflow-x-hidden md:py-12 z-30">
            <div
            className={`relative w-full md:w-1/5 aspect-[4/3] border-4 border-waterDark`}
          >
              <Image
                src="https://sunset-inn-dev.s3.ca-central-1.amazonaws.com/forest-room/gallery/bbq-chicken-burger.webp"
                alt="BBQ chicken burger, house salad on the side"
                fill
                style={{ objectFit: 'cover' }}
              />
          </div>
          <div className={`relative w-full md:w-1/5 aspect-[4/3] border-4 border-waterLight md:scale-[1.1] z-20 elevation-10`}>
              <Image
                src="https://sunset-inn-dev.s3.ca-central-1.amazonaws.com/forest-room/gallery/grilled-prime-rib.webp"
                alt="Grilled prime rib sandwich, fries on the side"
                fill
                style={{ objectFit: 'cover' }}
              />
          </div>
          <div className={`relative w-full md:w-1/5 aspect-[4/3] border-4 border-island md:scale-[1.2] z-10 elevation-5`}>
            <Image
              src="https://sunset-inn-dev.s3.ca-central-1.amazonaws.com/forest-room/gallery/soup-and-sandwich.webp"
              alt="Soup of the day with a corned beef sandwich"
              fill
              style={{ objectFit: 'cover' }}
            />
          </div>
          <div className={`relative w-full md:w-1/5 aspect-[4/3] border-4 border-waterLight md:scale-[1.1] z-20 elevation-10`}>
              <Image
                src="https://sunset-inn-dev.s3.ca-central-1.amazonaws.com/forest-room/gallery/supreme-pizza-flatbread.webp"
                alt="Supreme pizza flatbread"
                fill
                style={{ objectFit: 'cover' }}
              />
            </div>
            <div className={`relative w-full md:w-1/5 aspect-[4/3] border-4 border-waterDark`}>
              <Image
                src="https://sunset-inn-dev.s3.ca-central-1.amazonaws.com/forest-room/gallery/philly-cheese-steak.webp"
                alt="Philly cheese steak sandwich, fries on the side"
                fill
                style={{ objectFit: 'cover' }}
              />
            </div>
            </div>
          </AnimateOnVisible>
          <AnimateOnVisible duration={200} delay={500}>
            <div className="md:mt-20 mb-10 w-full flex justify-center z-10">
              <Link href="/the-forest-room-restaurant">
                <Button onClick={() => {}}>SEE OUR MENU</Button>
              </Link>
            </div>
          </AnimateOnVisible>
        </div>
      </div>
      <div
        style={{
          backgroundImage: `url('https://sunset-inn-dev.s3.ca-central-1.amazonaws.com/main/assets/map-tile.webp')`,
          backgroundRepeat: 'repeat',
        }}
        className="w-full py-5 bg-sun dark:bg-island z-10 md:mt-10 relative border-4 border-island flex flex-col items-center"
      >
        <div className="absolute inset-0 bg-gradient-to-br from-lightblue via-white to-lightblue dark:from-black dark:via-island dark:to-black opacity-80" />
        <div className="w-full flex flex-col items-center py-2 text-center text-3xl text-island font-overlock font-black dark:text-sun py-2 relative">
          <div className="w-full lg:w-1/3">
            <AnimateOnVisible duration={100} delay={400}>
              Location!
            </AnimateOnVisible>
          </div>
          <div className="w-full text-4xl lg:w-1/3">
            <AnimateOnVisible duration={100} delay={500}>
              Location!
            </AnimateOnVisible>
          </div>
          <div className="w-full text-5xl lg:w-1/3">
            <AnimateOnVisible duration={100} delay={600}>
              Location!
            </AnimateOnVisible>
          </div>
        </div>
        <div className="w-full font-overlock text-center text-2xl md:text-3xl text-turkeyred dark:text-lightblue py-2 relative px-4 font-cabin font-bold">
          <AnimateOnVisible duration={200} delay={900}>
            {' '}
            What&apos;s near by the Sunset Inn Kenora?{' '}
          </AnimateOnVisible>
        </div>
        <div className="w-full text-center text-2xl text-island dark:text-sun px-2 py-5 lg:py-0 relative font-overlock font-bold mb-2">
          <AnimateOnVisible duration={200} delay={900}>
            How about every convenience you need for a great vacation!
          </AnimateOnVisible>
        </div>
        <div className="w-full text-center text-xl text-island dark:text-lightblue px-2 relative font-overlock font-bold">
          <AnimateOnVisible duration={200} delay={900}>
            Near by you will find restaurants, shopping destinations, the City
            of Kenora dog park, gas, cannabis, and more!
          </AnimateOnVisible>
        </div>
        <div className="w-full lg:w-3/4 flex flex-wrap items-center justify-center mt-4 lg:px-20 border-y-[5px] border-y-island py-3">
          {categories.map((category, key) => (
            <Link
              key={category.name}
              className="z-50 w-1/5 lg:w-1/5 "
              href={`/near-by?d=${category.icon}`}
            >
              <div
                className={`w-full flex flex-col justify-center items-center transition duration-250 border-2 border-transparent`}
              >
                <Icon
                  type={category.icon}
                  className={`h-[50px] lg:h-[100px] cwmax-w-[100px] lg:max-w-[100px] fill-island ${
                    key % 2
                      ? 'fill-island dark:fill-sky'
                      : 'fill-turkeyred dark:fill-sun'
                  }`}
                  pathClassName=""
                />
                <div className="font-overlock">{category.name}</div>
              </div>
            </Link>
          ))}
        </div>
        <AnimateOnVisible delay={700} duration={200}>
          <>
            <div className="w-full text-center text-xl text-island dark:text-lightblue px-2 relative font-overlock font-bold bg-opacity-20 py-3">
              Click any of the icons above to see a map of what&apos;s near by!
            </div>

            <div className="w-full flex justify-center">
              <Link
                href={`https://www.onressystems.com/reservations/search?property=sunset-inn`}
              >
                <Button disabled>BOOK A ROOM (coming soon)</Button>
              </Link>
            </div>
          </>
        </AnimateOnVisible>
      </div>
      <div className="flex flex-col items-center z-10 w-full bg-gradient-to-t from-sky via-sun to-sky dark:from-black dark:via-island dark:to-black relative py-10 border-y-4 border-island dark:border-sun">
        <div
          className="absolute inset-0 rounded"
          style={{
            backgroundImage: `url(https://sunset-inn-dev.s3.ca-central-1.amazonaws.com/main/assets/bricks.webp)`,
            objectFit: 'cover',
          }}
        />
        <div className="relative bg-transparent w-full xl:w-3/4 rounded-[10px] lg:rounded-[100px] flex flex-col items-center px-10">
          <AnimateOnVisible duration={200} delay={700}>
            <CapricornLogo className="w-[250px] lg:w-[350px]" />
          </AnimateOnVisible>
          <div className="font-overlock w-full rounded-full text-center font-black text-2xl mt-4 text-island bg-gradient-to-tr from-sun via-white to-sun z-10 py-10 lg:px-10 border-4 border-island elevation-20 flex flex-col md:flex-row justify-center items-center">
            <div className="relative rounded-full bg-black border-[20px] border-island md:px-14 md:py-4">
              <div
                className="absolute inset-0 rounded-full"
                style={{
                  backgroundImage: `url(https://sunset-inn-dev.s3.ca-central-1.amazonaws.com/main/assets/bricks.webp)`,
                  objectFit: 'cover',
                }}
              />
              <div className="absolute inset-0 rounded-full bg-white blur-xl opacity-30" />
              <AnimateOnVisible duration={200} delay={1000}>
                <div className="absolute blur">
                  <Microphone className="w-[100px] lg:w-[200px]" />
                </div>
                <div className="absolute blur-xl">
                  <Microphone className="w-[100px] lg:w-[200px]" />
                </div>
                <div className="relative">
                  <Microphone className="w-[100px] lg:w-[200px]" />
                </div>
                <div className="relative">
                  <Saturday
                    className="w-[100px] lg:w-[200px]"
                    classNames={{
                      saturday1: 'fill-waterDark',
                      saturday2: 'fill-waterLight',
                      saturday3: 'fill-island',
                      saturday4: 'fill-sun',
                      night: 'fill-sun',
                    }}
                  />
                </div>
              </AnimateOnVisible>
            </div>
            <div className="flex flex-col items-center justify-center w-5/6 md:w-1/2 ml-2 lg:ml-0">
              <AnimateOnVisible duration={200} delay={1000}>
                <div className="w-full text-center text-xl lg:text-2xl">
                  Join us every{' '}
                </div>
                <div className="w-full text-center">
                  <b className="text-2xl lg:text-3xl">Saturday</b>
                </div>
                <div className="w-full text-center text-xl lg:text-2xl">
                  for a night of{' '}
                </div>
                <div className="w-full text-center">
                  <b className="text-2xl text-3xl">karaoke</b>, drinks, and fun
                  at{' '}
                </div>
                <b className="text-xl lg:text-2xl">The Capricorn Lounge!</b>
                <Link href="/the-capricorn-lounge">
                  <Button>SEE DRINK MENU</Button>
                </Link>
              </AnimateOnVisible>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="w-full relative z-10">
        <Map/>
      </div> */}
      <Footer copyrightText={globalData.footerText} />
      <GradientBackground
        variant="large"
        className="fixed top-20 opacity-40 dark:opacity-60"
      />
      <GradientBackground
        variant="small"
        className="absolute bottom-0 opacity-20 dark:opacity-10"
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
      />
    </Layout>
  );
}

export function getStaticProps() {
  const globalData = getGlobalData();

  return { props: { globalData } };
}
