
export default function Header({ name }) {
  return (
    <header className="pt-10 md:pt-20">
      {/* <div className="w-10 h-10 xl:w-12 xl:h-12 rounded-full block mx-auto bg-gradient-conic from-gradient-3 to-gradient-4" /> */}
      {/* <p className="text-2xl dark:text-white text-center"> */}
        {/* <Link href="/">
          <a>{name}</a>
        </Link> */}
      {/* </p> */}
    </header>
  );
}
