import { Logo } from './'

const sunIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    fill="none"
    viewBox="0 0 25 24"
    className="dark:opacity-50"
  >
    <g
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      clipPath="url(#clip0_192_823)"
    >
      <path d="M12.5 17a5 5 0 100-10 5 5 0 000 10zM12.5 1v2M12.5 21v2M4.72 4.22l1.42 1.42M18.86 18.36l1.42 1.42M1.5 12h2M21.5 12h2M4.72 19.78l1.42-1.42M18.86 5.64l1.42-1.42"></path>
    </g>
    <defs>
      <clipPath id="clip0_192_823">
        <path
          className="fill-current text-white"
          d="M0 0H24V24H0z"
          transform="translate(.5)"
        ></path>
      </clipPath>
    </defs>
  </svg>
);

const moonIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="20"
    fill="none"
    viewBox="0 0 21 20"
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      className="stroke-current text-gray-400 dark:text-white"
      d="M19.5 10.79A9 9 0 119.71 1a7 7 0 009.79 9.79v0z"
    ></path>
  </svg>
);

const ThemeSwitcher = () => {
  return (
    <div className="flex mt-6 bg-white justify-center dark:bg-gray-900 rounded-3xl p-1">
      <button
        type="button"
        aria-label="Use Dark Mode"
        onClick={() => {
          document.documentElement.classList.add('dark');
          localStorage.setItem('theme', 'dark');
        }}
        className="flex items-center h-full pr-2 dark:bg-primary rounded-3xl flex justify-center align-center p-2 w-24 h-10 transition"
      >
        {moonIcon}
      </button>

      <button
        type="button"
        aria-label="Use Light Mode"
        onClick={() => {
          document.documentElement.classList.remove('dark');
          localStorage.setItem('theme', 'light');
        }}
        className="flex items-center h-full pr-2 bg-primary dark:bg-transparent rounded-3xl flex justify-center align-center p-2 w-24 h-10 transition"
      >
        {sunIcon}
      </button>
    </div>
  );
};

export default function Footer({ copyrightText }) {
  return (
    <footer className="flex flex-col border-[15px] border-island items-center w-full z-10 relative bg-gradient-to-b from-lightblue via-sun to-waterLight dark:from-island dark:via-island dark:to-black font-overlock">
      <div className="relative lg:absolute lg:inset-0 flex justify-center items-center">
        <div className="relative w-[225px] h-[225px] rounded-full bg-gradient-to-b from-waterLight via-sun to-waterDark blur opacity-60"/>
        <Logo className="absolute mb-5 w-1/6 min-w-[200px] w-[200px] z-10" />
      </div>
      <div className="flex flex-col lg:flex-row w-full">
        <div className="w-full lg:w-1/2 flex flex-col items-center justify-center my-5">
        <h3 className="font-overlock font-black text-3xl">Sunset Inn Kenora</h3>
        <div className="text-4xl"><a href="tel:18004682003">1 (807) 468-2003</a></div>
          <div className="text-2xl">920 Highway 17 East</div>
          <div className="text-xl">Kenora, Ontario</div>
          <div className="text-xl">P9N1L8</div>
        </div>
        <div className="w-1/2 mb-2"></div>
      </div>
      <div className="flex flex-col lg:flex-row w-full">
      <div className="w-full lg:w-1/2 h-full flex flex-col items-center justify-center my-5">
      <h3 className="font-overlock font-black text-2xl">The Forest Room Restaurant</h3>
        <div className="text-xl lg:text-2xl"><a href="tel:18004682003">1 (807) 468-2003</a> Extension:300</div>
      <h3 className="font-overlock font-black text-2xl">The Capricorn Lounge</h3>
        <div className="text-xl lg:text-2xl"><a href="tel:18004682003">1 (807) 468-2003</a> Extension:300</div>
      </div>
      <div className="w-1/2 h-full"></div>
      </div>
    </footer>
  );
}
